var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.txId
    ? _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c("div", { staticClass: "my" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$store.state.userInfo &&
                    _vm.$store.state.userInfo.metaId === _vm.$route.params.txId
                    ? _vm.$t("lang.me")
                    : _vm.userData.name
                ) +
                " "
            ),
          ]),
          _c("div", { staticClass: "userinfo" }, [
            _c("div", { staticClass: "info" }, [
              _c("div", { staticClass: "view" }, [
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.userData.name)),
                ]),
                _c("div", { staticClass: "metaid" }, [
                  _c("span", [
                    _vm._v(
                      "MetaID: " +
                        _vm._s(
                          _vm.userData.metaId &&
                            _vm.userData.metaId.substr(0, 6)
                        )
                    ),
                  ]),
                  _c("span", { staticClass: "tx", on: { click: _vm.viewTX } }, [
                    _vm._v("TX"),
                  ]),
                ]),
              ]),
              _vm.$store.state.userInfo &&
              _vm.$store.state.userInfo.metaId === _vm.$route.params.txId
                ? _c(
                    "div",
                    { staticClass: "edit", on: { click: _vm.cancel_follow } },
                    [_vm._v(_vm._s(_vm.$t("lang.edit")))]
                  )
                : _c("div", { staticClass: "operate" }, [
                    _c(
                      "div",
                      {
                        staticClass: "privateLetters",
                        on: {
                          click: function ($event) {
                            return _vm.privateLetters()
                          },
                        },
                      },
                      [_vm._v("私信")]
                    ),
                    _vm.user.isFollow
                      ? _c(
                          "div",
                          {
                            staticClass: "followed",
                            on: {
                              click: function ($event) {
                                return _vm.followTa(_vm.user)
                              },
                            },
                          },
                          [_c("div")]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "follow",
                            on: {
                              click: function ($event) {
                                return _vm.followTa(_vm.user)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("lang.follow")))]
                        ),
                  ]),
            ]),
            _c("div", { staticClass: "num" }, [
              _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.mediaTotal || 0))]),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#BEBFC5",
                      "font-size": "14px",
                      "font-weight": "500",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("lang.media")))]
                ),
              ]),
              _c("div", [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.followData.followedList &&
                        _vm.followData.followedList.length) ||
                        0
                    )
                  ),
                ]),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#BEBFC5",
                      "font-size": "14px",
                      "font-weight": "500",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("lang.followers")))]
                ),
              ]),
              _c("div", [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.followData.followingList &&
                        _vm.followData.followingList.length) ||
                        0
                    )
                  ),
                ]),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#BEBFC5",
                      "font-size": "14px",
                      "font-weight": "500",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("lang.following")))]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "avatar" }, [
            _c("img", { attrs: { src: _vm.avatar, alt: "" } }),
          ]),
          _c("div", { staticClass: "tabs" }, [
            _c(
              "span",
              {
                staticClass: "tab_item",
                class: { actived: _vm.tab === 0 },
                on: {
                  click: function ($event) {
                    return _vm.clickTab(0)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("lang.media")))]
            ),
            _c(
              "span",
              {
                staticClass: "tab_item",
                class: { actived: _vm.tab === 1 },
                on: {
                  click: function ($event) {
                    return _vm.clickTab(1)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("lang.favorites")))]
            ),
          ]),
          _vm.list && _vm.list.length
            ? [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tab === 0,
                        expression: "tab=== 0",
                      },
                    ],
                    staticClass: "photos",
                  },
                  _vm._l(_vm.list, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "photo",
                        on: {
                          click: function ($event) {
                            return _vm.toDetail(item.buzzTxId)
                          },
                        },
                      },
                      [
                        item.fileDataType.includes("video/")
                          ? [
                              _c("video", {
                                staticStyle: {
                                  width: "100%",
                                  height: "100%",
                                  cursor: "pointer",
                                  "border-radius": "6px",
                                },
                                attrs: {
                                  src: _vm._f("videoFilters")(item.txId),
                                  poster: _vm.getPoster(item.txId),
                                  "webkit-playsinline": "true",
                                  playsinline: "true",
                                  "x5-playsinline": "true",
                                  "object-fit": "contain",
                                },
                              }),
                            ]
                          : [
                              _c("van-image", {
                                staticStyle: { cursor: "pointer" },
                                attrs: {
                                  src: item.resUrl,
                                  fit: "cover",
                                  alt: "",
                                  width: "100%",
                                  height: "100%",
                                },
                              }),
                            ],
                        _c("div", {
                          class: {
                            icon_img: item.fileDataType.includes("image/"),
                            icon_video: item.fileDataType.includes("video/"),
                          },
                        }),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]
            : [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tab === 0,
                        expression: "tab=== 0",
                      },
                    ],
                    staticClass: "no_photos",
                  },
                  [
                    _c("div", [_vm._v(_vm._s(_vm.$t("lang.media")))]),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("lang.uploadTips")),
                      },
                    }),
                  ]
                ),
              ],
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tab === 1,
                  expression: "tab=== 1",
                },
              ],
              staticClass: "no_photos",
            },
            [
              _c("div", [_vm._v(_vm._s(_vm.$t("lang.favorites")))]),
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.$t("lang.favTips")) },
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tab === 2,
                  expression: "tab=== 2",
                },
              ],
              staticClass: "no_photos",
            },
            [
              _c("div", [_vm._v(_vm._s(_vm.$t("lang.private")))]),
              _c("div", [_vm._v(_vm._s(_vm.$t("lang.privateTips")))]),
            ]
          ),
          _c(
            "van-popup",
            {
              staticClass: "more_popup",
              attrs: { round: "" },
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _c("div", { staticClass: "tips" }, [
                _c("img", { attrs: { src: _vm.avatar, alt: "" } }),
                _c("span", [_vm._v("停止关注 Sudio ？")]),
              ]),
              _c("div", [_vm._v("停止关注")]),
              _c("div", { on: { click: _vm.close } }, [_vm._v("取消")]),
            ]
          ),
        ],
        2
      )
    : _c(
        "div",
        { staticClass: "wrapper_nologin" },
        [
          _c("div", { staticClass: "my" }, [
            _vm._v(" " + _vm._s(_vm.$t("lang.me")) + " "),
          ]),
          _c("div", { staticClass: "userinfo" }, [
            _c("div", { staticClass: "info" }, [
              _c(
                "div",
                {
                  staticClass: "login",
                  on: {
                    click: function ($event) {
                      return _vm.auth(2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang.connectWallet")))]
              ),
            ]),
            _c("div", { staticClass: "num" }, [
              _c("div", [
                _c("span", [_vm._v("0")]),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#BEBFC5",
                      "font-size": "14px",
                      "font-weight": "500",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("lang.likes")))]
                ),
              ]),
              _c("div", [
                _c("span", [_vm._v("0")]),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#BEBFC5",
                      "font-size": "14px",
                      "font-weight": "500",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("lang.followers")))]
                ),
              ]),
              _c("div", [
                _c("span", [_vm._v("0")]),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#BEBFC5",
                      "font-size": "14px",
                      "font-weight": "500",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("lang.following")))]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "avatar" }, [
            _c("img", { attrs: { src: _vm.avatar } }),
          ]),
          _c("div", { staticClass: "tabs" }, [
            _c(
              "span",
              {
                staticClass: "tab_item",
                class: { actived: _vm.tab === 0 },
                on: {
                  click: function ($event) {
                    return _vm.clickTab(0)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("lang.media")))]
            ),
            _c(
              "span",
              {
                staticClass: "tab_item",
                class: { actived: _vm.tab === 1 },
                on: {
                  click: function ($event) {
                    return _vm.clickTab(1)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("lang.favorites")))]
            ),
          ]),
          _vm.list && _vm.list.length
            ? [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tab === 0,
                        expression: "tab=== 0",
                      },
                    ],
                    staticClass: "photos",
                  },
                  _vm._l(_vm.list, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "photo",
                        on: {
                          click: function ($event) {
                            return _vm.toDetail(item.buzzTxId)
                          },
                        },
                      },
                      [
                        item.fileDataType.includes("video/")
                          ? [
                              _c("video", {
                                staticStyle: {
                                  width: "100%",
                                  height: "100%",
                                  cursor: "pointer",
                                },
                                attrs: {
                                  src: _vm._f("videoFilters")(item.txId),
                                  poster: _vm.getPoster(item.txId),
                                  "webkit-playsinline": "true",
                                  playsinline: "true",
                                  "x5-playsinline": "true",
                                  "object-fit": "contain",
                                },
                              }),
                            ]
                          : [
                              _c("van-image", {
                                staticStyle: { cursor: "pointer" },
                                attrs: {
                                  src: item.resUrl,
                                  fit: "cover",
                                  alt: "",
                                  width: "100%",
                                  height: "100%",
                                },
                              }),
                            ],
                        _c("div", {
                          class: {
                            icon_img: item.fileDataType.includes("image/"),
                            icon_video: item.fileDataType.includes("video/"),
                          },
                        }),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]
            : [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tab === 0,
                        expression: "tab=== 0",
                      },
                    ],
                    staticClass: "no_photos",
                  },
                  [
                    _c("div", [_vm._v(_vm._s(_vm.$t("lang.media")))]),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("lang.uploadTips")),
                      },
                    }),
                  ]
                ),
              ],
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tab === 1,
                  expression: "tab=== 1",
                },
              ],
              staticClass: "no_photos",
            },
            [
              _c("div", [_vm._v(_vm._s(_vm.$t("lang.favorites")))]),
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.$t("lang.favTips")) },
              }),
            ]
          ),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }