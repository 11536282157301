<template>
  <div class="wrapper" v-if="txId">
    <div class="my">
      {{ $store.state.userInfo && $store.state.userInfo.metaId === $route.params.txId ? $t('lang.me') : userData.name }}
    </div>
    <div class="userinfo">
      <div class="info">
        <div class="view">
          <div class="name">{{ userData.name }}</div>
          <div class="metaid">
            <span>MetaID: {{ userData.metaId && userData.metaId.substr(0, 6) }}</span>
            <span class="tx" @click="viewTX">TX</span>
          </div>
        </div>
        <div class="edit" @click="cancel_follow" v-if="$store.state.userInfo && $store.state.userInfo.metaId === $route.params.txId">{{ $t('lang.edit') }}</div>
        <div class="operate" v-else>
          <div class="privateLetters" @click="privateLetters()">私信</div>
          <div class="followed" v-if="user.isFollow" @click="followTa(user)">
            <div></div>
          </div>
          <div class="follow" v-else @click="followTa(user)">{{$t('lang.follow')}}</div>
        </div>
      </div>
      <div class="num">
        <div>
          <span>{{ mediaTotal || 0 }}</span>
          <span style="color: #BEBFC5; font-size: 14px; font-weight: 500">{{ $t('lang.media') }}</span>
        </div>
        <div>
          <span>{{ followData.followedList && followData.followedList.length || 0 }}</span>
          <span style="color: #BEBFC5; font-size: 14px; font-weight: 500">{{ $t('lang.followers') }}</span>
        </div>
        <div>
          <span>{{ followData.followingList && followData.followingList.length || 0 }}</span>
          <span style="color: #BEBFC5; font-size: 14px; font-weight: 500">{{ $t('lang.following') }}</span>
        </div>
      </div>
    </div>
    <div class="avatar">
      <img :src="avatar" alt="">
    </div>
    <div class="tabs">
      <span class="tab_item" :class="{'actived': tab===0}" @click="clickTab(0)">{{ $t('lang.media') }}</span>
      <span class="tab_item" :class="{'actived': tab===1}" @click="clickTab(1)">{{ $t('lang.favorites') }}</span>
<!--      <span class="tab_item" :class="{'actived': tab===2}" @click="clickTab(2)">{{ $t('lang.private') }}</span>-->
    </div>
    <template v-if="list && list.length">
      <div class="photos" v-show="tab=== 0">
        <div class="photo" v-for="(item, index) in list" :key="index" @click="toDetail(item.buzzTxId)">
          <template v-if='item.fileDataType.includes("video/")'>
            <video :src="item.txId | videoFilters" :poster="getPoster(item.txId)"
                   webkit-playsinline="true" playsinline="true" x5-playsinline="true" object-fit="contain" style="width: 100%; height: 100%; cursor: pointer; border-radius: 6px" />
          </template>
          <template v-else>
            <van-image :src="item.resUrl" fit="cover" alt="" width="100%" height="100%" style="cursor: pointer" />
          </template>
          <div :class="{icon_img: item.fileDataType.includes('image/'), icon_video: item.fileDataType.includes('video/')}"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="no_photos" v-show="tab=== 0">
        <div>{{ $t('lang.media') }}</div>
        <!--      <div>上传你珍贵的照片和视频<br>分享美好时光</div >-->
        <div v-html="$t('lang.uploadTips')"></div>
      </div>
    </template>
    <div class="no_photos" v-show="tab=== 1">
      <div>{{ $t('lang.favorites') }}</div>
<!--      <div>收藏感兴趣的照片和视频，方便再次查看，<br>收藏内容只对你可见，且不会通知任何人。</div>-->
      <div v-html="$t('lang.favTips')"></div>
    </div>
    <div class="no_photos" v-show="tab=== 2">
      <div>{{ $t('lang.private') }}</div>
<!--      <div>私密内容只对你可见，且不会通知任何人。</div>-->
      <div>{{ $t('lang.privateTips') }}</div>
    </div>
    <van-popup v-model="show" round class="more_popup">
      <div class="tips">
        <img :src="avatar" alt="">
        <span>停止关注 Sudio ？</span>
      </div>
      <div>停止关注</div>
      <div @click="close">取消</div>
    </van-popup>
  </div>
  <div class="wrapper_nologin" v-else>
    <div class="my">
      {{ $t('lang.me') }}
    </div>
    <div class="userinfo">
      <div class="info">
        <div class="login" @click="auth(2)">{{ $t('lang.connectWallet') }}</div>
      </div>
      <div class="num">
        <div>
          <span>0</span>
          <span style="color: #BEBFC5; font-size: 14px; font-weight: 500">{{ $t('lang.likes') }}</span>
        </div>
        <div>
          <span>0</span>
          <span style="color: #BEBFC5; font-size: 14px; font-weight: 500">{{ $t('lang.followers') }}</span>
        </div>
        <div>
          <span>0</span>
          <span style="color: #BEBFC5; font-size: 14px; font-weight: 500">{{ $t('lang.following') }}</span>
        </div>
      </div>
    </div>
    <div class="avatar">
      <img :src="avatar">
    </div>
    <div class="tabs">
      <span class="tab_item" :class="{'actived': tab===0}" @click="clickTab(0)">{{ $t('lang.media') }}</span>
      <span class="tab_item" :class="{'actived': tab===1}" @click="clickTab(1)">{{ $t('lang.favorites') }}</span>
    </div>
    <template v-if="list && list.length">
      <div class="photos" v-show="tab=== 0">
        <div class="photo" v-for="(item, index) in list" :key="index" @click="toDetail(item.buzzTxId)">
          <template v-if='item.fileDataType.includes("video/")'>
            <video :src="item.txId | videoFilters" :poster="getPoster(item.txId)"
                   webkit-playsinline="true" playsinline="true" x5-playsinline="true" object-fit="contain" style="width: 100%; height: 100%; cursor: pointer" />
          </template>
          <template v-else>
            <van-image :src="item.resUrl" fit="cover" alt="" width="100%" height="100%" style="cursor: pointer" />
          </template>
          <div :class="{icon_img: item.fileDataType.includes('image/'), icon_video: item.fileDataType.includes('video/')}"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="no_photos" v-show="tab=== 0">
        <div>{{ $t('lang.media') }}</div>
<!--      <div>上传你珍贵的照片和视频<br>分享美好时光</div >-->
        <div v-html="$t('lang.uploadTips')"></div>
      </div>
    </template>
    <div class="no_photos" v-show="tab=== 1">
      <div>{{ $t('lang.favorites') }}</div>
<!--      <div>收藏感兴趣的照片和视频，方便再次查看，<br>收藏内容只对你可见，且不会通知任何人。</div>-->
      <div v-html="$t('lang.favTips')"></div>
    </div>
  </div>
</template>

<script>
import { Popup } from 'vant'
import { getMyPiazzaList, getMyFollow, getUserInfo } from 'api/api'
import common from '@/utils/common'
export default {
  name: "User2",
  data() {
    return {
      show: false,
      tab: 0,
      userData: {},
      followData: {},
      list: [],
      avatar: '',
      mediaTotal: 0,
      userInfo: {},
      user: {}
    }
  },
  components: {
    [Popup.name]: Popup
  },
  created() {
    this.avatar = `${process.env.VUE_APP_AppImgApi}/metafile/avatar/${this.$route.params.txId}` // 未登录取默认头像
    getUserInfo({
      metaId: this.$route.params.txId || localStorage.getItem('showId')
    }).then(res => {
      this.userData = res.data
    })

    getMyFollow({
      metaId: this.$route.params.txId || localStorage.getItem('showId')
    }).then(res => {
      this.followData = res.data
      if (this.$route.params.txId === localStorage.getItem('showId')) {
        localStorage.setItem('followData', JSON.stringify(res.data))
      }
    })
  },
  computed: {
    txId() {
      return localStorage.getItem('showId')
    },
    getPoster () {
      return (value) => {
        if (!value) return ''
        return `${process.env.VUE_APP_AppImgApi}/metafile/videoImg/${value}`
      }
    }
  },
  mounted() {
    let followData = localStorage.getItem('followData')
    this.user = {
      metaId: this.$store.state.otherUserInfo.metaId,
      isFollow: followData ? (JSON.parse(followData).followingList ? JSON.parse(followData).followingList : []).includes(this.$store.state.otherUserInfo.metaId) : false,
      zeroAddress: this.$store.state.otherUserInfo.zeroAddress
    }

    let params = {
      metaId: this.$route.params.txId || localStorage.getItem('showId'),
      page: 1,
      pageSize: 100,
      timestamp: 0
    }
    getMyPiazzaList(params).then(res => {
      res.data.results.items.forEach(item => {
        item.resUrl = item.resUrl.replace(process.env.VUE_APP_AuthUrl, process.env.VUE_APP_AppImgApi)
      })
      let data = {
        results: {
          items: res.data.results.items
        },
        total: res.data.total
      }
      this.mediaTotal = res.data.total
      this.list = data.results.items
    })
  },
  methods: {
    viewTX() {
      let txId = this.userData.metaId
      location.href = `https://whatsonchain.com/tx/${txId}`
    },
    cancel_follow() {
      // this.show = true
      location.href = process.env.VUE_APP_AuthUrl
    },
    close() {
      this.show = false
    },
    clickTab(index) {
      this.tab = index
    },
    auth(appType) {
      if (this.$store.state.userInfoLoading) return
      this.$store.state.sdk?.changeSdkType(appType)
      this.$store.state.sdk?.login()
    },
    toDetail(txId) {
      this.$router.push({
        path: '/square/detail',
        query: {
          buzzTxId: txId
        }
      })
    },
    followTa(item) {
      if (!this.$store.state.userInfo) {
        this.$toast(this.$t('lang.loginTips'))
        return
      }
      let params = {
        "createTime": +new Date(),
        "MetaID": item.metaId,
        "status": item.isFollow ? -1 : 1, // 1添加关注，-1取消关注
        "pay": 2000,
        "payTo": item.zeroAddress
      }
      debugger

      const txData = common.getTxData({
        nodeName: 'PayFollow',
        brfcId: '8f23b51a047b',
        path: '/Protocols/PayFollow',
        data: JSON.stringify(params),
        attachments: [],
        payTo: [
          {
            amount: 2000,
            address: item.zeroAddress
          },
          {
            amount: 500,
            address: process.env.VUE_APP_PlatformAddress
          }
        ]
      })

      this.$store.state.sdk.sendMetaDataTx(txData).then(res=>{
        item.isFollow = !item.isFollow
        this.$toast(this.$t('lang.success'))
      }, (err) => {

      }).then(res => {
        getMyFollow({
          metaId: localStorage.getItem('showId')
        }).then(res => {
          this.followData = res.data
          localStorage.setItem('followData', JSON.stringify(res.data))
        })
      })
    },
    privateLetters() {
      const url = `${process.env.VUE_APP_showtalk}/chat/user/${this.userData.metaId}`
      location.href = url
    }
  },
  filters: {
    videoFilters(value) {
      if (!value) return ''
      return `${process.env.VUE_APP_AppImgApi}/metafile/${value}`
    },
  }
}
</script>

<style lang="less" scoped>
  .wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 600Px;
    margin: 0 auto;
    background-color: #F5F7FA;
    .my {
      height: 115px;
      background-color: #E3ECFF;
      background-image: url("../../assets/img/user/bg.png");
      background-size:  100% 100%;
      color: #617FFF;
      font-size: 20px;
      text-align: center;
      line-height: 115px;
    }
    .userinfo {
      height: 150px;
      background-color: #FFFFFF;
      border-radius: 0 0 18px 18px;
      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 20px 0 115px;
        .view {
          .name {
            color: #000000;
            font-size: 20px;
            line-height: 28px;
          }
          .metaid {
            color: #BFC2CC;
            line-height: 17px;
            display: flex;
            align-items: center;
            .tx {
              margin-left: 8px;
              padding: 3px 6px;
              border-radius: 4px;
              background: #e3f6fc;
              color: #2391ef;
              font-weight: 500;
              cursor: pointer;
            }
          }
        }
        .edit {
          color: #FFFFFF;
          background-color: #617FFF;
          border-radius: 6px;
          width: 80px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          cursor: pointer;
        }
        .operate {
          display: flex;
          .followed {
            background-color: #FFFFFF;
            width: 48px;
            height: 22px;
            border-radius: 12px;
            border: 1Px solid #617FFF;
            display: flex;
            justify-content: center;
            div {
              width: 5px;
              height: 13px;
              border-color: #617FFF;
              border-style: solid;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
          }
          .follow {
            background-color: #617FFF;
            width: 48px;
            height: 22px;
            border-radius: 12px;
            border: 1Px solid #617FFF;
            color: #FFFFFF;
            text-align: center;
            line-height: 24px;
          }
          .privateLetters {
            background-color: #FFFFFF;
            width: 48px;
            height: 22px;
            border-radius: 12px;
            border: 1Px solid #617FFF;
            color: #617FFF;
            text-align: center;
            line-height: 24px;
            margin-right: 8px;
          }
        }
      }
      .num {
        padding: 37px 20px 10px;
        color: #000000;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        justify-content: space-around;
        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 56px;
        }
      }
    }
    .avatar {
      padding-left: 21px;
      img {
        width: 70px;
        height: 70px;
        padding: 7px;
        border-radius: 50%;
        position: absolute;
        top: 94px;
        background-color: #fff;
      }
    }
    .tabs {
      color: #000000;
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 55px;
      padding: 0 17px;
      .tab_item {
        padding-bottom: 7px;
        border-bottom: 2Px solid #F5F7FA; // ignored
        //width: 94px;
        //margin: 0 38px;
        flex: 1;
        text-align: center;
        cursor: pointer;
        &.actived {
          color: #617FFF;
          border-bottom: 2Px solid #617FFF; // ignored
        }
      }
      .fav_tab {
        padding-bottom: 7px;
        border-bottom: 2Px solid #F5F7FA; // ignored
        width: 74px;
        margin: 0 38px;
        text-align: center;
      }
    }
    .photos {
      display: flex;
      //justify-content: space-between;
      justify-content: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      margin: 10px 10px 10px 20px;
      //margin-right: -10px;
      overflow: auto; // 滚动
      position: absolute;
      bottom: 50px;
      top: 320px;
      .photo {
        width: 105px;
        height: 105px;
        margin-bottom: 10px;
        margin-right: 10px;
        position: relative;
        //&:nth-child(3n) {
        //  margin-right: 0;
        //}
        /deep/ img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }
        .icon_img {
          width: 20px;
          height: 20px;
          background-image: url("../../assets/img/user/icon_img.png");
          background-size: 100% 100%;
          position: absolute;
          right: 4px;
          bottom: 4px;
        }
        .icon_video {
          width: 20px;
          height: 20px;
          background-image: url("../../assets/img/user/icon_video.png");
          background-size: 100% 100%;
          position: absolute;
          right: 4px;
          bottom: 4px;
        }
      }
    }
    .no_photos {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 121px 20px 0;
      color: #BEBFC5;
      > div:first-child {
        font-size: 30px;
      }
      > div:last-child {
        font-size: 14px;
        text-align: center;
      }
    }
    .more_popup {
      div {
        width: 260px;
        height: 50px;
        font-size: 14px;
        color: #313131;
        text-align: center;
        line-height: 50px;
        border: 1px solid #E3E2E2;
        &:nth-child(1) {
          height: 170px;
        }
        &:nth-child(2) {
          color: #FF4D4D;
        }
        &.tips {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-top: 25px;
          }
        }
      }
    }
}
  .wrapper_nologin {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #F5F7FA;
    .my {
      height: 115px;
      background-color: #E3ECFF;
      background-image: url("../../assets/img/user/bg.png");
      background-size:  100% 100%;
      color: #617FFF;
      font-size: 20px;
      text-align: center;
      line-height: 115px;
    }
    .userinfo {
      height: 150px;
      background-color: #FFFFFF;
      border-radius: 0 0 18px 18px;
      position: relative;
      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: 15px;
        right: 20px;
        cursor: pointer;
        .login {
          color: #FFFFFF;
          background-color: #617FFF;
          border-radius: 6px;
          width: 121px;
          height: 30px;
          line-height: 30px;
          text-align: center;
        }
      }
      .num {
        padding: 37px 20px 10px;
        color: #000000;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        justify-content: space-around;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 56px;
        }
      }
    }
    .avatar {
      padding-left: 21px;
      img {
        width: 70px;
        height: 70px;
        padding: 7px;
        border-radius: 50%;
        position: absolute;
        top: 94px;
        background-color: #fff;
      }
    }
    .tabs {
      color: #000000;
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 55px;
      padding: 0 17px;
      .tab_item {
        padding-bottom: 7px;
        border-bottom: 2Px solid #F5F7FA; // ignored
        //width: 74px;
        //margin: 0 38px;
        flex: 1;
        text-align: center;
        cursor: pointer;
        &.actived {
          color: #617FFF;
          border-bottom: 2Px solid #617FFF; // ignored
        }
      }
    }
    .photos {
      display: flex;
      //justify-content: space-between;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 10px 10px 10px 20px;
      //margin-right: -10px;
      overflow: auto; // 滚动
      position: absolute;
      bottom: 50px;
      top: 320px;
      .photo {
        width: 105px;
        height: 105px;
        margin-bottom: 10px;
        margin-right: 10px;
        position: relative;
        //&:nth-child(3n) {
        //  margin-right: 0;
        //}
        /deep/ img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }
        .icon_img {
          width: 20px;
          height: 20px;
          background-image: url("../../assets/img/user/icon_img.png");
          background-size: 100% 100%;
          position: absolute;
          right: 4px;
          bottom: 4px;
        }
        .icon_video {
          width: 20px;
          height: 20px;
          background-image: url("../../assets/img/user/icon_video.png");
          background-size: 100% 100%;
          position: absolute;
          right: 4px;
          bottom: 4px;
        }
      }
    }
    .no_photos {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 121px 20px 0;
      color: #BEBFC5;
      > div:first-child {
        font-size: 30px;
      }
      > div:last-child {
        font-size: 14px;
        text-align: center;
      }
    }
}
</style>
